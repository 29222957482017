<template>
  <div v-if="loaded">
    <div
      class="relative h-auto px-6 py-16 bg-center bg-cover lg:h-128 lg:p-0"
      :style="
        'background-image: url(' +
        settings.DOMAIN +
        settings.BACK_IMG +
        page.bg.path +
        ')'
      "
    >
      <div class="absolute inset-0 bg-black bg-opacity-40"></div>
      <div class="flex flex-col items-center justify-center h-full">
        <h1
          data-aos="fade-right"
          data-aos-delay="350"
          class="relative inline-block px-8 py-3 mr-10 text-3xl font-bold bg-white  lg:text-5xl lg:mr-24 bg-opacity-80"
        >
          {{ page.bt }}
        </h1>
        <h1
          data-aos="fade-left"
          data-aos-delay="350"
          class="relative inline-block px-10 py-3 mb-20 ml-10 text-3xl font-normal bg-white  lg:text-5xl lg:ml-28 bg-opacity-80"
        >
          {{ page.bt2 }}
          <router-link
            :to="'/samochody'"
            class="absolute bottom-0 right-0 flex items-center p-4 px-8 font-medium text-white transform translate-y-full bg-black rounded  text-17 oswald tracking-osw hover:bg-gray-900"
          >
            <svg
              class="mr-4"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 20H5V21C5 21.2652 4.89464 21.5196 4.70711 21.7071C4.51957 21.8946 4.26522 22 4 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V13.5L0.757 13.19C0.540753 13.1358 0.348809 13.011 0.211655 12.8352C0.0745022 12.6595 5.86043e-06 12.4429 0 12.22L0 11.5C0 11.3674 0.0526784 11.2402 0.146447 11.1464C0.240215 11.0527 0.367392 11 0.5 11H2L4.48 5.212C4.63432 4.852 4.89096 4.54524 5.21805 4.32978C5.54515 4.11432 5.92832 3.99965 6.32 4H17.68C18.0713 4.00004 18.4541 4.1149 18.7808 4.33033C19.1075 4.54577 19.3638 4.85231 19.518 5.212L22 11H23.5C23.6326 11 23.7598 11.0527 23.8536 11.1464C23.9473 11.2402 24 11.3674 24 11.5V12.22C24 12.4429 23.9255 12.6595 23.7883 12.8352C23.6512 13.011 23.4592 13.1358 23.243 13.19L22 13.5V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H20C19.7348 22 19.4804 21.8946 19.2929 21.7071C19.1054 21.5196 19 21.2652 19 21V20ZM20 18V13H4V18H20ZM5.477 11H18.523C18.6863 10.9999 18.8471 10.9598 18.9914 10.8832C19.1357 10.8066 19.2589 10.6958 19.3505 10.5605C19.442 10.4253 19.4991 10.2697 19.5166 10.1073C19.5341 9.94488 19.5116 9.78067 19.451 9.629L18 6H6L4.549 9.629C4.4884 9.78067 4.46588 9.94488 4.4834 10.1073C4.50092 10.2697 4.55795 10.4253 4.64951 10.5605C4.74106 10.6958 4.86435 10.8066 5.0086 10.8832C5.15285 10.9598 5.31367 10.9999 5.477 11V11ZM5 14C7.317 14 8.879 14.755 9.686 16.264C9.72679 16.3402 9.74714 16.4257 9.74506 16.5121C9.74297 16.5985 9.71852 16.6829 9.6741 16.757C9.62968 16.8311 9.5668 16.8925 9.49159 16.9351C9.41639 16.9777 9.33143 17 9.245 17H6C5.73478 17 5.48043 16.8946 5.29289 16.7071C5.10536 16.5196 5 16.2652 5 16V14ZM19 14V16C19 16.2652 18.8946 16.5196 18.7071 16.7071C18.5196 16.8946 18.2652 17 18 17H14.755C14.6687 16.9999 14.5838 16.9774 14.5088 16.9347C14.4337 16.892 14.3709 16.8307 14.3266 16.7566C14.2823 16.6825 14.258 16.5981 14.2559 16.5118C14.2539 16.4255 14.2742 16.3401 14.315 16.264C15.12 14.754 16.682 14 19 14Z"
                fill="white"
              />
            </svg>
            znajdź samochód
          </router-link>
        </h1>
      </div>
    </div>
    <div class="bggradient">
      <div class="container relative mx-auto">
        <div class="pt-12 text-center">
          <svg
            class="mx-auto"
            width="16"
            height="28"
            viewBox="0 0 16 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.29289 27.7071C7.68342 28.0976 8.31658 28.0976 8.70711 27.7071L15.0711 21.3431C15.4616 20.9526 15.4616 20.3195 15.0711 19.9289C14.6805 19.5384 14.0474 19.5384 13.6569 19.9289L8 25.5858L2.34314 19.9289C1.95262 19.5384 1.31946 19.5384 0.928931 19.9289C0.538407 20.3195 0.538407 20.9526 0.928931 21.3431L7.29289 27.7071ZM7 -4.37114e-08L7 27L9 27L9 4.37114e-08L7 -4.37114e-08Z"
              fill="black"
            />
          </svg>

          <h2 class="mt-12 -mb-4 font-bold text-primary-200">{{ page.wn }}</h2>
          <h2 class="text-primary-200">{{ page.wn2 }}</h2>

          <div
            class="mt-10 text-primary-200 text-23 xl:px-56"
            v-html="page.wt"
          ></div>
          <router-link
            class="flex items-center justify-center mx-auto my-16 text-sm font-bold  text-primary-300 hover:text-primary-200"
            :to="'/samochody'"
          >
            <svg
              class="mr-4 fill-current"
              width="26"
              height="16"
              viewBox="0 0 26 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z"
                fill=""
              />
            </svg>

            <h4>Zapoznaj się z naszą ofertą</h4>
          </router-link>
        </div>

        <div class="mt-28">
          <div class="flex items-end justify-between">
            <h2 class="font-medium text-35">Wyróżnione</h2>
            <router-link
              :to="'/samochody'"
              class="font-medium  oswald text-primary-300 tracking-osw hover:text-primary-200"
            >
              zobacz wszystkie
            </router-link>
          </div>
        </div>

        <recentPost
          :settings="settings"
          count="6"
          class="flex flex-wrap mt-2 mb-4 lg:-mx-12 xl:-mx-24"
        ></recentPost>
      </div>
    </div>

    <div class="container relative mx-auto">
      <div
        class="flex flex-wrap items-center px-8 py-20 mt-16 border rounded  lg:px-24 lg:mt-28 bg-primary-400 border-primary-500"
      >
        <svg
          class="mr-6"
          width="54"
          height="54"
          viewBox="0 0 54 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="53"
            height="53"
            rx="2.5"
            fill="white"
            stroke="#E5E5E5"
          />
          <path
            d="M34 35H20V36C20 36.2652 19.8946 36.5196 19.7071 36.7071C19.5196 36.8946 19.2652 37 19 37H18C17.7348 37 17.4804 36.8946 17.2929 36.7071C17.1054 36.5196 17 36.2652 17 36V28.5L15.757 28.19C15.5408 28.1358 15.3488 28.011 15.2117 27.8352C15.0745 27.6595 15 27.4429 15 27.22V26.5C15 26.3674 15.0527 26.2402 15.1464 26.1464C15.2402 26.0527 15.3674 26 15.5 26H17L19.48 20.212C19.6343 19.852 19.891 19.5452 20.2181 19.3298C20.5452 19.1143 20.9283 18.9997 21.32 19H32.68C33.0713 19 33.4541 19.1149 33.7808 19.3303C34.1075 19.5458 34.3638 19.8523 34.518 20.212L37 26H38.5C38.6326 26 38.7598 26.0527 38.8536 26.1464C38.9473 26.2402 39 26.3674 39 26.5V27.22C39 27.4429 38.9255 27.6595 38.7883 27.8352C38.6512 28.011 38.4592 28.1358 38.243 28.19L37 28.5V36C37 36.2652 36.8946 36.5196 36.7071 36.7071C36.5196 36.8946 36.2652 37 36 37H35C34.7348 37 34.4804 36.8946 34.2929 36.7071C34.1054 36.5196 34 36.2652 34 36V35ZM35 33V28H19V33H35ZM20.477 26H33.523C33.6863 25.9999 33.8471 25.9598 33.9914 25.8832C34.1357 25.8066 34.2589 25.6958 34.3505 25.5605C34.442 25.4253 34.4991 25.2697 34.5166 25.1073C34.5341 24.9449 34.5116 24.7807 34.451 24.629L33 21H21L19.549 24.629C19.4884 24.7807 19.4659 24.9449 19.4834 25.1073C19.5009 25.2697 19.558 25.4253 19.6495 25.5605C19.7411 25.6958 19.8643 25.8066 20.0086 25.8832C20.1529 25.9598 20.3137 25.9999 20.477 26V26ZM20 29C22.317 29 23.879 29.755 24.686 31.264C24.7268 31.3402 24.7471 31.4257 24.7451 31.5121C24.743 31.5985 24.7185 31.6829 24.6741 31.757C24.6297 31.8311 24.5668 31.8925 24.4916 31.9351C24.4164 31.9777 24.3314 32 24.245 32H21C20.7348 32 20.4804 31.8946 20.2929 31.7071C20.1054 31.5196 20 31.2652 20 31V29ZM34 29V31C34 31.2652 33.8946 31.5196 33.7071 31.7071C33.5196 31.8946 33.2652 32 33 32H29.755C29.6687 31.9999 29.5838 31.9774 29.5088 31.9347C29.4337 31.892 29.3709 31.8307 29.3266 31.7566C29.2823 31.6825 29.258 31.5981 29.2559 31.5118C29.2539 31.4255 29.2742 31.3401 29.315 31.264C30.12 29.754 31.682 29 34 29Z"
            fill="black"
          />
        </svg>

        <h3 class="font-medium text-35">
          {{ page.bdt }}
        </h3>
        <router-link
          :to="'/samochody'"
          class="flex items-center p-4 px-8 mt-4 font-medium text-white bg-black rounded  md:m-0 md:ml-auto text-15 oswald tracking-osw hover:bg-gray-900"
        >
          zaczynajmy
        </router-link>
      </div>
    </div>
    <teleport to="[data-teleport]">
      <title>{{ options.title }} - {{ options.description }}</title>
      <meta property="og:description" :content="options.description" />
    </teleport>
  </div>
</template> 

<route>
  {
  name: "Home",
  meta: {
  requiresAuth: false,
  description: "description",
  }
  }
</route>

<script>
import recentPost from "../components/RecentPosts.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
//https://splidejs.com/guides/options/
export default {
  data() {
    return {
      page: [],
      loaded: false,
    };
  },

  components: {
    recentPost,
    Splide,
    SplideSlide,
  },
  props: ["settings", "options"],
  beforeMount() {
    fetch(
      this.settings.DOMAIN +
        "/backend/api/singletons/get/home?token=" +
        this.settings.TOKEN
    )
      .then((res) => res.json())
      .then((res) => {
        this.page = res;
        this.loaded = true;
      });
  },
};
</script>