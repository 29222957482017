<template>
  <router-link class="group" :to="isEl">
    <div class=""></div>
    {{ item.Tytuł }}</router-link
  >
</template>

 <script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  computed: {
    isEl: function () {
      if (this.item.Url) {
        if (this.item.Url == "/") return this.item.Url;
        else if (this.item.Url !== "#") return "/" + this.item.Url;
        else return { path: "/", hash: "#" + this.item.Tytuł };
      } else {
        if (this.item.el._link == "page") return "/" + this.item.el.title_slug;
        else return "/" + this.item.el._link + "/" + this.item.el.title_slug;
      }
    },
  },
  components: {},
};
</script>