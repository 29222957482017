export default {


    DOMAIN: 'https://nazaruk.miyostudio.pl',
    
    BASE_IMG: '/src/assets/images/',
    BACK_IMG: '/backend/storage/uploads/',


    // COCKPIT SETTINGS

    TOKEN: "5bdceefc06ec7f75e466fba97e7339",


  };
  