<template>
  <Slide
    :closeOnNavigation="true"
    :isOpen="isOpen"
    @closeMenu="isOpen = false"
    noOverlay
    :burgerIcon="false"
    class="block lg:hidden"
  >
    <ul class="flex-col w-full p-0 m-0 cd-accordion-menu">
      <template v-for="item in options.menu2" :key="item">
        <itemDesktop class="text-lg" :item="item.value"></itemDesktop>
      </template>
    </ul>
  </Slide>
  <header class="bg-black">
    <div class="container relative mx-auto">
      <div class="flex flex-wrap items-center py-3">
        <router-link to="/" class="w-60">
          <img
            alt=""
            :src="settings.DOMAIN + settings.BACK_IMG + options.logo.path"
          />
        </router-link>
        <div
          class="hidden ml-12 font-medium text-white  lg:block tracking-osw text-15"
        >
          {{ options.adres }}
        </div>
        <nav class="items-center hidden ml-auto text-white lg:flex">
          <template v-for="(item, index) in options.menu2" :key="item">
            <itemDesktop
              v-if="index == 0"
              class="px-8 py-1 ml-3 mr-3 font-medium text-black transition-none duration-100 bg-white border border-white rounded  tracking-osw text-15 hover:bg-gray-200"
              :item="item.value"
            ></itemDesktop>
            <itemDesktop
              v-else
              class="px-5 py-1 ml-3 mr-3 font-medium transition-none duration-100 border border-white rounded  tracking-osw text-15 hover:bg-white hover:text-black"
              :item="item.value"
            ></itemDesktop>
          </template>
        </nav>

        <div class="flex items-center">
          <svg
            class="mx-3"
            width="1"
            height="12"
            viewBox="0 0 1 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.5"
              y1="-2.18557e-08"
              x2="0.500001"
              y2="12"
              stroke="white"
            />
          </svg>

          <a class="hover:text-primary-100" :href="options.fb"
            ><svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.17383 9.3125L8.58398 6.61719H5.97656V4.85938C5.97656 4.09766 6.32812 3.39453 7.5 3.39453H8.70117V1.08008C8.70117 1.08008 7.61719 0.875 6.5918 0.875C4.45312 0.875 3.04688 2.19336 3.04688 4.53711V6.61719H0.644531V9.3125H3.04688V15.875H5.97656V9.3125H8.17383Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
        <button
          class="ml-auto text-white lg:hidden"
          v-if="!isOpen"
          @click="isOpen = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-12 h-12"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M0 7.897C0 7.402.402 7 .897 7h22.206a.897.897 0 010 1.794H.897A.897.897 0 010 7.897zM0 16.196c0-.495.402-.897.897-.897h14.58a.897.897 0 010 1.794H.897A.897.897 0 010 16.197z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { Slide } from "vue3-burger-menu"; // import the CSS transitions you wish to use, in this case we are using `Slide`
import item from "./menu.vue";
import itemDesktop from "./menuDesktop.vue";

export default {
  props: ["options", "settings"],
  data() {
    return {
      isOpen: false,
    };
  },
  components: {
    Slide, // Register your component
    item: item,
    itemDesktop,
  },
};
</script>