//import { Fancybox } from "@fancyapps/ui";
//import '@fancyapps/ui/dist/fancybox.css';

//Fancybox.bind("[data-fancybox]", { });


import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init(); 

window.onload = function () {



}

    //others

    // $('body').on("click", ".tabItem", function (e) {
    // });


