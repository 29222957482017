<script setup>
</script>

<template>
  <div v-if="loaded">
    <nav style="display: none" id="menu" class="p-10 pr-0 shadow-lg lg:hidden">
      <h2
        class="block pb-2 mt-2 text-2xl font-semibold border-b border-gray-500 border-opacity-25  text-black2 fira"
      >
        Menu
      </h2>
    </nav>
    <Header :options="options" :settings="SETTINGS" />
    <div class="">
      <router-view
        :options="options"
        :settings="SETTINGS"
        :key="$route.fullPath"
      />
    </div>
    <Footer :options="options" :settings="SETTINGS" />
    <cookie v-if="options.ga" :options="options"></cookie>
  </div>
</template>




<script>
import Header from "./components/Header.vue";
import Footer from "./components/footer.vue";
import SETTINGS from "./settings";
import cookie from "./components/cookie.vue";

export default {
  data() {
    return {
      SETTINGS: SETTINGS,
      loaded: false,
      options: [],
    };
  },

  components: {
    Header,
    Footer,
    cookie,
  },

  beforeMount() {
    fetch(
      SETTINGS.DOMAIN +
        "/backend/api/singletons/get/options?populate=4&token=" +
        SETTINGS.TOKEN
    )
      .then((res) => res.json())
      .then((res) => {
        this.options = res;
        this.loaded = true;
      });
  },
};
</script>