<template>
  <div class="">
    <div
      v-for="item in items"
      :key="item"
      class="w-full mt-10 mb-16 lg:px-12 xl:px-24 lg:w-1/3"
    >
      <router-link :to="'/samochody/' + item._id">
        <img
          class="w-full"
          :src="
            settings.DOMAIN +
            '/backend/api/cockpit/image?token=' +
            settings.TOKEN +
            '&src=' +
            settings.DOMAIN +
            settings.BACK_IMG +
            item.image.path +
            '&w=360&h=216&o=true&q=85'
          "
        />
      </router-link>
      <router-link class="block mt-6" :to="'/samochody/' + item._id"
        ><h3 class="leading-none text-28">
          {{ item.title }}
          <div class="text-base font-normal">{{ item.model }}</div>
        </h3></router-link
      >
      <div class="flex items-center justify-between mt-4">
        <h4 class="text-sm font-bold text-primary-300">
          {{ numberWithSpaces(item.price) }} PLN
        </h4>
        <router-link
          class="inline-block p-1 px-2 text-xs font-medium border rounded-full  text-primary-300 oswald tracking-osw border-primary-300 hover:bg-black hover:text-white hover:border-black"
          :to="'/samochody/' + item._id"
        >
          zobacz
        </router-link>
      </div>
    </div>
  </div>
</template>


<script>
import SETTINGS from "../settings";

export default {
  data() {
    return {
      items: [],
    };
  },
  props: ["count", "settings"],
  methods: {
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  beforeMount() {
    fetch(
      SETTINGS.DOMAIN +
        "/backend/api/collections/get/samochody?token=" +
        SETTINGS.TOKEN +
        "&filter[published]=true&filter[feathome]=true&limit=6&sort[_created]=-1"
    )
      .then((res) => res.json())
      .then((res) => {
        this.items = res.entries;
      });
  },
};
</script>