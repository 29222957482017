<template>
	<footer class="mt-20 text-white bg-black lg:mt-40 pt-28 footer">
		<div class="container relative mx-auto">
			<div class="flex flex-wrap">
				<div class="w-full lg:w-7/12">
					<h2 class="font-bold text-43">{{ options.st }}</h2>
					<h2 class="mt-1 text-3xl font-normal">{{ options.st2 }}</h2>
					<div class="mt-12 lg:mt-24">
						<a class="hover:text-primary-100" :href="options.fb">
							<svg
								width="11"
								height="20"
								viewBox="0 0 9 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.17383 9.3125L8.58398 6.61719H5.97656V4.85938C5.97656 4.09766 6.32812 3.39453 7.5 3.39453H8.70117V1.08008C8.70117 1.08008 7.61719 0.875 6.5918 0.875C4.45312 0.875 3.04688 2.19336 3.04688 4.53711V6.61719H0.644531V9.3125H3.04688V15.875H5.97656V9.3125H8.17383Z"
									fill="white"
								/>
							</svg>
						</a>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full pt-10 lg:w-5/12">
							<a v-if="options.tel" :href="'tel:' + options.tel">
								<h4 class="text-xl font-normal hover:text-primary-300">tel. {{ options.tel }}</h4>
							</a>
							<a class="inline-block mt-3" v-if="options.email" :href="'mailto:' + options.email">
								<h4 class="text-xl font-normal hover:text-primary-300">{{ options.email }}</h4>
							</a>
						</div>
						<div class="w-full pt-10 lg:w-1/2">
							<h4 class="-mt-1 text-xl font-normal leading-loose whitespace-pre-line">
								<a target="blank" :href="options.mapa_url">{{ options.adres2 }}</a>
							</h4>
						</div>
					</div>
				</div>
				<div class="w-full mt-10 lg:text-right lg:w-5/12 lg:mt-0">
					<h2 class="font-bold text-43">Popularne</h2>
					<div class="mt-8">
						<router-link :to="'/samochody/' + item._id" v-for="item in items" :key="item">
							<h4 class="block mb-2 text-lg font-normal hover:text-primary-300">{{ item.title }}</h4>
						</router-link>
					</div>
				</div>
			</div>

			<div class="flex flex-wrap justify-between py-8 border-t mt-28 border-primary-200">
				<div class>
					<nav class="flex items-center ml-auto text-white">
						<template v-for="(item, index) in options.menu_stopka" :key="item">
							<itemDesktop
								class="mr-5 text-xs font-normal poppins hover:text-primary-300"
								:item="item.value"
							></itemDesktop>
						</template>
					</nav>
				</div>

				<div class="flex items-center text-xs poppins">
					Made with
					<svg
						class="mx-3"
						width="12"
						height="11"
						viewBox="0 0 12 11"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6 10.8571C6.10714 10.8571 6.21429 10.817 6.29464 10.7366L10.4665 6.71875C10.5268 6.65848 12 5.3125 12 3.70536C12 1.7433 10.8013 0.571428 8.79911 0.571428C7.62723 0.571428 6.52902 1.49554 6 2.01786C5.47098 1.49554 4.37277 0.571428 3.20089 0.571428C1.19866 0.571428 0 1.7433 0 3.70536C0 5.3125 1.47321 6.65848 1.52679 6.70536L5.70536 10.7366C5.78571 10.817 5.89286 10.8571 6 10.8571Z"
							fill="white"
						/>
					</svg>

					<a class="hover:text-primary-300" href="https://happyrebels.com">Happy Rebels</a>
					<span class="mx-1">&amp;</span>
					<a class="hover:text-primary-300" href="https://miyostudio.pl">MiyoStudio</a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	import itemDesktop from "./menuDesktop.vue";

	export default {
		props: ["options", "settings"],
		data() {
			return {
				items: [],
			};
		},
		components: {
			itemDesktop,
		},
		beforeMount() {
			fetch(
				this.settings.DOMAIN +
					"/backend/api/collections/get/samochody?token=" +
					this.settings.TOKEN,
				{
					method: "post",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						filter: { published: true, feat: true },
						sort: { _created: -1 },
						limit: 6,
					}),
				}
			)
				.then((res) => res.json())
				.then((res) => {
					this.items = res.entries;
				});
		},
	};
</script> 