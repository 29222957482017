<template>
  <div
    v-if="!status"
    class="fixed bottom-0 left-0 max-w-sm p-4 px-6 m-8 bg-white rounded-lg shadow-lg "
  >
    <div class="flex flex-wrap items-center">
      <svg
        class="w-9"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <g clip-path="url(#clip0_165:673)">
          <path
            fill-rule="evenodd"
            d="M11.027 2.973C5.474 2.973.973 7.474.973 13.027s4.501 10.054 10.054 10.054 10.054-4.501 10.054-10.054l-.001-.175a4.01 4.01 0 01-.323.013c-2 0-3.664-1.483-3.87-3.407l-.103.001a3.81 3.81 0 01-3.487-5.349 3.835 3.835 0 01-.834-1.035 10.144 10.144 0 00-1.436-.102zM0 13.027C0 6.937 4.937 2 11.027 2c.626 0 1.24.052 1.838.153.159.026.294.13.361.276.212.459.546.855.964 1.147.207.145.269.425.141.644a2.838 2.838 0 002.94 4.225.487.487 0 01.569.498 2.9 2.9 0 00-.002.111c0 1.556 1.296 2.838 2.919 2.838.233 0 .46-.027.676-.076a.487.487 0 01.595.44c.017.255.026.512.026.771 0 6.09-4.937 11.027-11.027 11.027C4.937 24.054 0 19.117 0 13.027zm21.287-8.344a.487.487 0 01.444-.06l1.946.695a.487.487 0 01.274.671l-.649 1.332a.486.486 0 01-.348.265l-1.297.244a.486.486 0 01-.576-.479v-2.27c0-.158.077-.306.206-.398zm.767 1.088v.994l.48-.09.304-.624-.784-.28zM6.876 6.9a.486.486 0 01.475.067l2.108 1.621c.194.15.247.42.123.632L8.203 11.57a.486.486 0 01-.614.2l-1.298-.567a.487.487 0 01-.284-.526l.567-3.406a.486.486 0 01.302-.372zM7.4 8.232l-.372 2.23.554.243.943-1.608L7.4 8.232zm8.167 6.255c0-.27.217-.487.486-.487a1.784 1.784 0 11-1.784 1.784.486.486 0 11.973 0 .81.81 0 10.811-.811.486.486 0 01-.486-.487zm-7.61.992a.487.487 0 01.38.098l1.54 1.216a.487.487 0 01.177.477l-.406 2.027a.486.486 0 01-.732.319l-2.321-1.432a.487.487 0 01-.152-.68l1.186-1.812a.487.487 0 01.329-.213zm.195 1.191l-.621.948 1.3.803.21-1.049-.89-.702z"
            clip-rule="evenodd"
          />
          <path
            d="M23.838 10.757a.649.649 0 11-1.297 0 .649.649 0 011.297 0z"
          />
        </g>
        <defs>
          <clipPath id="clip0_165:673"><path d="M0 0h24v24H0z" /></clipPath>
        </defs>
      </svg>

      <h3 class="ml-6 text-xl font-semibold">Cookie</h3>
      <div class="w-full mt-6 text-sm">
        {{ options.cookie }}
      </div>
      <div class="w-full mb-4 text-sm" v-html="options.cookie_policy"></div>

      <a @click="acceptOnly" href="#" class="mr-5">{{ options.cookie_only }}</a>
      <a @click="accept" href="#" class="p-1 px-5 rounded-lg bg-primary-100">{{
        options.cookie_accept
      }}</a>
    </div>
  </div>
</template>


<script>
import { useState } from "vue-gtag-next";
export default {
  data() {
    return {
      status: false,
    };
  },
  props: ["options"],
  methods: {
    acceptOnly() {
      this.setCookie("cookie", "1", 120);
      this.status = true;
    },
    accept() {
      this.setCookie("cookie", "2", 120);
      this.status = true;
      this.enable();
    },
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    enable() {
      const { property } = useState();
      property.value = {
        id: this.options.ga,
      };
    },
  },
  mounted() {
    let cookie = this.getCookie("cookie");
    if (cookie == 1 || cookie == 2) {
      this.status = true;
    }

    if (cookie == 2) {
      this.enable();
    }
  },
};
</script>