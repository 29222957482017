import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router' 
import { createApp } from 'vue'
import routes from "virtual:generated-pages";

import './assets/css/index.css'
import './scripts.js'

import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox.css';

Fancybox.bind("[data-fancybox]", { Carousel: {
  Navigation: {
    prevTpl:
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11 5l-7 7 7 7"/><path d="M4 12h16"/></svg>',
    nextTpl:
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4 12h16"/><path d="M13 5l7 7-7 7"/></svg>',
  },
},});



const app = createApp(App)

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  app.use(router)
  app.mount('#app')
  app.config.globalProperties.$filters = {
    trim(text, length, clamp) {
      clamp = clamp || '...';
      var node = document.createElement('div');
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    }
  }


